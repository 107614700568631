import React from "react";
import {graphql} from "gatsby";
import Markdown from "markdown-to-jsx";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import Form from "/src/components/forms/Form";

import Col from "../../components/styled/grid/Col";
import {Title} from "../../components/styled/typography/Typography";

const TrainingTemplate = ({data}) => {

    const {training} = data

    return (
        <Layout lang={training.lang} seo={training.SEO}>
            <SectionLayoutGrid customBackground="middle">
                <Col>
                    <Title color="white">
                        {training.title}
                    </Title>
                </Col>
                <Col className="markdown">
                    <Markdown children={training.content}/>
                </Col>
                <Col>
                    <Form form={training} lang={training.lang} isTraining={true}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleTrainingSubpage($slug: String) {
         training: strapiTrainingSubpages(slug: { eq: $slug }) {
           title
           content
           lang
           Form {
            company
            company_address
            contact_person_email
            contact_person_name
            contact_person_phone
            country
            email
            first_name
            help_dropdown
            job_title
            last_name
            message
            phone
            preffered_dropdown
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
          }
   }
 `

export default TrainingTemplate;
